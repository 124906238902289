<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
                <div v-if="isItem" class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Product</label>
                        <AsyncSelect
                            :api-service="fetchProductList"
                            placeholder="Select Product"
                            v-model="selectedProduct"
                            label="text"
                            :format-label="option => option.text"

                        />
                    </div>
                </div>
                <div v-else class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Account Heads</label>
                        <vField
                            v-model="data.account_head_id"
                            name="product_id"
                            type="text"
                            class="form-control d-none"
                        />
                        <v-select
                            placeholder="Select Head"
                            v-model="data.account_head_id"
                            :options="accountHeads"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                </div>

                <template v-if="isItem">
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="rate">Unit</label>
                            <vField
                                v-model="unit"
                                name="unit"
                                class="form-control text-right"
                                disabled="disabled"
                            />
                        </div>
                    </div>
                    <div class="col-md-3 col-12" v-if="alternativeUnits.length > 0">
                      <div class="mb-1">
                        <label class="form-label" for="product_id">Alternative Unit</label>
                        <vField
                          v-model="data.alt_unit_id"
                          name="alt_unit_id"
                          type="text"
                          class="form-control d-none"
                        />
                        <v-select
                          placeholder="Select alternative unit"
                          v-model="data.alt_unit_id"
                          :options="alternativeUnits"
                          label="name"
                          :reduce="name => name.id"
                        />
                      </div>
                    </div>
                    <div :class="alternativeUnits.length > 0 ? 'col-md-3' : 'col-md-2'" class="'col-12'">
                        <div class="mb-1">
                            <label class="form-label" for="rate">Rate</label>
                            <vField
                                v-model="data.rate"
                                name="rate"
                                type="number"
                                class="form-control text-right"
                            />
                        </div>
                    </div>
                    <div class="col-md-2 col-12" v-if="alternativeUnits.length > 0">
                      <div class="mb-1">
                        <label class="form-label" for="quantity">Alt Unit Quantity</label>
                        <input
                          v-model="data.alt_unit_qty"
                          name="Received"
                          type="number"
                          class="form-control text-right"
                        />
                      </div>
                    </div>
                    <div class="col-md-2 col-12">
                      <div class="mb-1">
                        <label class="form-label" for="quantity">
                          Quantity <span v-if="productStock !== null">({{productStock}})</span>
                        </label>
                        <vField
                          v-model="data.quantity"
                          name="quantity"
                          type="number"
                          class="form-control text-right"
                          @blur="validateQuantity()"
                        />
                      </div>
                    </div>
                </template>

                <div v-if="isItem" class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            readonly
                            v-model="amount"
                            name="amount text-right"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                </div>

                <div v-else class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            v-model="data.amount"
                            name="amount"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="discount-percent">Discount Percent</label>
                        <vField
                            v-model="data.discount_percent"
                            name="discount-percent"
                            type="number"
                            class="form-control text-right"
                            max="100"
                            min="0"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="discount-amount">Discount Amount</label>
                        <vField
                            v-model="data.discount_amount"
                            name="discount-amount"
                            type="number"
                            class="form-control text-right"
                            min="0"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount after discount</label>
                        <vField
                            v-model="amountAfterDiscount"
                            name="amount"
                            type="number"
                            class="form-control text-right"
                            readonly=""
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat">VAT</label>
                        <vField
                            v-model="data.vat"
                            id="vat"
                            as="select"
                            name="vat"
                            class="form-select"
                        >
                            <option
                                v-for="(vat, i) in vatRate"
                                :value="vat.value"
                                :key="i"
                            >
                                {{vat.label}}
                            </option>
                        </vField>
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat_amount">VAT amount</label>
                        <vField
                            v-model="data.vat_amount"
                            name="vat_amount"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Total Amount</label>
                        <vField
                            v-model="totalAmount"
                            readonly
                            name="total_amount"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                </div>

                <div v-show="isShowDropdown" class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Select Business Unit</label>
                        <v-select
                            placeholder="Select Business"
                            v-model="data.business_id"
                            :options="business"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                </div>

                <div v-show="isShowDropdown" class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Select Project</label>
                        <v-select
                            placeholder="Select Project"
                            v-model="data.project_id"
                            :options="projects"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                </div>

                <div v-show="isShowDropdown" class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Select Cost Centre</label>
                        <v-select
                            placeholder="Select Cost Centre"
                            v-model="data.cost_centre_id"
                            :options="costCentres"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                </div>

                <div v-if="isItem" class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Free quantity</label>
                        <vField
                            readonly
                            v-model="data.free_quantity"
                            name="amount text-right"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                </div>

                <div class="col-12">
                    <div class="mb-1">
                        <label class="form-label" for="description">Description</label>
                        <vField
                          style="height: 38px"
                          as="textarea"
                          name="description"
                          v-model="data.description"
                          class="form-control"
                        />
                    </div>
                </div>
            </div>
            <div
                @click="$emit('onClose', index)"
                class="
                d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer font-medium-3" ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref,computed } from "@vue/runtime-core"
import {inject, onMounted, watch} from "vue";
import handlePurchaseAndSales from "@/services/modules/purchase";
import {useRoute} from "vue-router";
import handleInventory from "@/services/modules/inventory";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";

const showError = inject('showError');

const {fetchProductList, fetchProductStock, fetchFreeQuantity} = handlePurchaseAndSales();
const route = useRoute();
const productStock = ref(null);
const selectedProduct = ref(null);
const {fetchProductAlternativeUnits} = handleInventory()
const alternativeUnits = ref([]);

const pr = defineProps({
  title: String,
  data: Object,
  isItem: Boolean,
  index: Number,
  vatRate: Array,
  accountHeads: Array,
  business: Array,
  projects: Array,
  costCentres: Array,
  locationId : {
      type: Number,
      default: null
  },
  visitClientId: {
    type: String,
    default: null
  },
  isEdit: {
    type: Boolean,
    defaultFetch: false
  },
  reRender: {
      type: Boolean,
      default: false
  }
})

const isShowDropdown = computed(() =>  !pr.visitClientId)
const isReRender = computed(() =>  !pr.reRender)

const amount = computed(() => {
    if(pr.isItem) {
        return pr.data.amount = (pr.data.rate * pr.data.quantity).toFixed(2)
    }
     if(!pr.isItem) {
         return pr.data.amount
     }
})

const amountAfterDiscount = computed(() => {
  const discountAmount = pr.data.discount_amount === '' ? 0 : parseFloat(pr.data.discount_amount)
  if(discountAmount === 0) {
    return amount.value;
  }
  return (amount.value - discountAmount).toFixed(2)
})

const vatAmount = computed(() => {
    if(pr.data.vat === 0) {
        return 0
    }

    if(pr.data.vat && pr.isItem) {
        return (pr.data.vat/100 * amountAfterDiscount.value).toFixed(2)
    }
    return (pr.data.vat/100 * amountAfterDiscount.value).toFixed(2);
})

const altQuantity = computed(() => {
  return pr.data.alt_unit_qty
})

const altUnit = computed(() => {
  return pr.data.alt_unit_id
})

watch(vatAmount, (newValue, oldValue) => {
  if(isNaN(oldValue)){
    pr.data.vat_amount  = pr.data.vat_amount;
  }else {
    pr.data.vat_amount  = newValue;
  }
})

watch(altQuantity, (newValue, oldValue) => {
  //to ignore the calculation set quantity during on page load during edit
  if(route.query.grnId && oldValue === null) {
    return;
  }

  if(newValue && pr.data.alt_unit_id) {
    const altUnit = alternativeUnits.value.find(altUnit => altUnit.id === pr.data.alt_unit_id)
    pr.data.quantity = newValue * altUnit.conversion_rate;
  }
})

watch(altUnit, (newValue, oldValue) => {
  //to ignore the calculation set quantity during on page load during edit
  if(route.query.grnId && oldValue === null) {
    return;
  }

  if(newValue && pr.data.alt_unit_qty) {
    const altUnit = alternativeUnits.value.find(altUnit => altUnit.id === newValue)
    pr.data.quantity = pr.data.alt_unit_qty * altUnit.conversion_rate;
  }
})

const totalAmount = computed(() => {
    if(pr.data.vat == 0) {
        return pr.data.total_amount = amountAfterDiscount.value
    }
    return pr.data.total_amount = (parseFloat(amountAfterDiscount.value) + parseFloat(pr.data.vat_amount)).toFixed(2)
})

const unit = computed(() => {
    if (selectedProduct.value && selectedProduct.value.description) {
        return selectedProduct.value.description.unit ? selectedProduct.value.description.unit.name : null;
    }

    return '';
})

const productId = computed(() => selectedProduct.value ? selectedProduct.value.id : null)
const accountHeadId = computed(() => pr.data.account_head_id)
const locationId = computed(() => pr.locationId)
const discountPercent = computed(() => {
  return pr.data.discount_percent
})

const setSelectedProduct = () => {
    selectedProduct.value = {
        ...pr.data.product,
        text: pr.data.product.name,
    };
}

watch(accountHeadId, (newValue) => {
    if(newValue){
        const selectedAccountHead = pr.accountHeads.find(acc => acc.id === newValue)
        pr.data.head = {
            'name': selectedAccountHead.name
        }
    }
})

watch(productId, (newValue) => {
    pr.data.product_id = newValue;

    if(newValue === null) {
        clearFields();
        return;
    }

    pr.data.product = {
        'name': selectedProduct.value.text
    }
    pr.data.product_type = selectedProduct.value.product_type;

    //check if the product is a service
    if(selectedProduct.value.product_type === 'services') {
        productStock.value = null;
        return;
    }

    fetchProductAlternativeUnits(newValue, `?company_id=${route.params.companyId}`).then(res => {
      alternativeUnits.value = res.data;
    })
    fetchStock(newValue);
})

watch(discountPercent, (value) => {
  let discount = 0;
  value = parseFloat(value);
  if(value && value > 0 && amount.value > 0) {
    discount = amount.value * (value/100);
  }
  pr.data.discount_amount = discount.toFixed(2);
})

watch(amount, (value) => {
  if (value > 0 && discountPercent && discountPercent.value > 0) {
    pr.data.discount_amount = (value * (discountPercent.value/100)).toFixed(2)
    return ;
  }
  pr.data.discount_amount = 0;
})

watch(isReRender, () => {
  if(isReRender && pr.data.product) {
    setSelectedProduct();
  }
})

//method
const fetchStock = (id) => {
  if (productId.value !== null && productId.value != undefined) {
    let query = `?company_id=${route.params.companyId}&skip_stock_check=1`;
    query += ((locationId.value !== null) ? `&location_id=${locationId.value}` : '');
    fetchProductStock(id, query).then(res => {
      productStock.value = null;
      if (res.status === false) {
        showError(res.message);
        return;
      }
      productStock.value = res.data.stock;
      pr.data.rate = pr.data.rate && pr.data.rate > 0 ? pr.data.rate : res.data.sales_rate
    })
  }
}

onMounted(() => {
    //check if the product is a service
    if (pr.data.product) {
        setSelectedProduct();
    }
})

//methods
const getFreeQuantity = () => {
    const queryObject = Object.assign({}, {
        company_id: route.params.companyId,
        quantity: pr.data.quantity
    })

    fetchFreeQuantity(productId.value, `?${new URLSearchParams(queryObject).toString()}`)
        .then(res => {
            if (!res.data) {
                pr.data.free_quantity = 0;
            }

            pr.data.free_quantity = res.data.free_quantity;
        }).catch(() => {
        pr.data.free_quantity = 0;
    });
}

const validateQuantity = () => {
    if(pr.data.quantity !== null && pr.data.quantity < 1){
        showError('Product Quantity must be greater than 0!')
    }

    if (pr.data.quantity !== null && pr.data.quantity > 0){
        getFreeQuantity();
    }

}

const clearFields = () => {
    selectedProduct.value = null;
    productStock.value = null;
    pr.data.product_id = null;
    pr.data.quantity = 0;
    pr.data.rate = 0;
}

</script>


<style scoped>
.journal-line{
        border: 1px solid #f0f2f5;
    }
    .journal-line:hover{
        background: #f0f2f5;
    }
</style>

